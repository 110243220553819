<div class="container">
  <b-overlay :show="processing" opacity="0.3" spinner-variant="primary" no-fade>
    <div class="terms-base-container">
      <div class="row text-center">
        <div class="col-12">
          <div class="terms-header">{{ title }}</div>
        </div>

        <div class="col-12">
          <div class="row row-100">
            <div class="agreement-margin">
              <label class="sub-title-style">{{ $t('message.message_terms_agreement') }}</label>
            </div>
            <div class="col-12">
              <div class="terms-scroll-box">
                <div class="terms-display-style" v-html="useragreement">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 agreement-margin checkbox-size-lg line-checkbox">
          <div></div>
          <b-form-checkbox v-model="isAgreementChecked">
            {{ checkBoxTitle }}
          </b-form-checkbox>
          <div>
            <a v-if="agreementType === 'personal_data_agreement'" :href="aboutPersonalDataUrl" target="_blank"
              rel="noopener noreferrer">{{ $t('message.link_about_handling_personal_data') }}</a>
          </div>
        </div>

        <div class="col-12 agreement-margin">
          <b-button variant="primary" :disabled="!isConfirmed" v-on:click="goToNext()">
            {{ $t('message.button_agreement_confirm') }}
          </b-button>
        </div>
      </div>
    </div>
  </b-overlay>
</div>