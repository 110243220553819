import Error from '@/common/nodejs/errors';

export default {
  /* eslint-disable-next-line */
  getErrorMessageForCode(code) {
    let messageKey = '';
    switch (code) {
      case Error.MISSING_AUTHENTICATION_TOKEN: messageKey = 'error.MISSING_AUTHENTICATION_TOKEN'; break;
      case Error.EXPIRED_TOKEN: messageKey = 'error.EXPIRED_TOKEN'; break;
      case Error.AUTHORIZER_FAILURE: messageKey = 'error.AUTHORIZER_FAILURE'; break;
      case Error.INVALID_SIGNATURE: messageKey = 'error.INVALID_SIGNATURE'; break;
      case Error.INVALID_SESSION_TOKEN: messageKey = 'error.INVALID_SESSION_TOKEN'; break;
      case Error.USER_ACCESS_PERMISSION_ERROR: messageKey = 'error.USER_ACCESS_PERMISSION_ERROR'; break;
      case Error.CLOUD_CONNECTOR_BAD_REQUEST: messageKey = 'error.CLOUD_CONNECTOR_BAD_REQUEST'; break;
      case Error.SYSTEM_UNDER_MAINTENANCE: messageKey = 'error.SYSTEM_UNDER_MAINTENANCE'; break;
      case Error.REPRO_CANT_START_SYSTEM_UNDER_MAINTENANCE: messageKey = 'error.REPRO_CANT_START_SYSTEM_UNDER_MAINTENANCE'; break;

      case Error.INPUT_USER_NAME_EMPTY: messageKey = 'error.INPUT_USER_NAME_EMPTY'; break;
      case Error.INPUT_PASSWORD_EMPTY: messageKey = 'error.INPUT_PASSWORD_EMPTY'; break;
      case Error.INVALID_INPUT_MISSING_PARAM: messageKey = 'error.INVALID_INPUT_MISSING_PARAM'; break;
      case Error.INVALID_INPUT_VERIFICATION_CODE: messageKey = 'error.INVALID_INPUT_VERIFICATION_CODE'; break;
      case Error.LOGIN_FAILED_COUNT_OVER: messageKey = 'error.LOGIN_FAILED_COUNT_OVER'; break;
      case Error.INVALID_PASSWORD_FORMAT: messageKey = 'error.INVALID_PASSWORD_FORMAT'; break;
      case Error.INVALID_PASSWORD_LENGTH: messageKey = 'error.INVALID_PASSWORD_LENGTH'; break;
      case Error.INVALID_PASSWORD_PARTIAL_MATCH: messageKey = 'error.INVALID_PASSWORD_PARTIAL_MATCH'; break;
      case Error.INVALID_PASSWORD_HISTORY: messageKey = 'error.INVALID_PASSWORD_HISTORY'; break;
      case Error.INVALID_INIT_PASSWORD_PARAMS: messageKey = 'error.INVALID_INIT_PASSWORD_PARAMS'; break;
      case Error.INVALID_INPUT_EMAIL: messageKey = 'error.INVALID_INPUT_EMAIL'; break;
      case Error.INVALID_INPUT_FORCE_REPRO_TARGET_ID: messageKey = 'error.INVALID_INPUT_FORCE_REPRO_TARGET_ID'; break;
      case Error.SWID_CONSENT_UPDATE_FAILED: messageKey = 'error.SWID_CONSENT_UPDATE_FAILED'; break;
      case Error.HWID_CONSENT_UPDATE_FAILED: messageKey = 'error.HWID_CONSENT_UPDATE_FAILED'; break;
      case Error.CONTINUOUS_REPRO_UPDATE_OF_CONSENT_ERROR: messageKey = 'error.CONTINUOUS_REPRO_UPDATE_OF_CONSENT_ERROR'; break;

      case Error.NO_NETWORK_ERROR: messageKey = 'error.NO_NETWORK_ERROR'; break;

      case Error.USER_NOT_FOUND: messageKey = 'error.USER_NOT_FOUND'; break;
      case Error.USER_UNAUTHORIZED: messageKey = 'error.USER_UNAUTHORIZED'; break;
      case Error.USER_ALREADY_LOGGEDIN: messageKey = 'error.USER_ALREADY_LOGGEDIN'; break;
      case Error.USER_NOT_LOGGEDIN: messageKey = 'error.USER_NOT_LOGGEDIN'; break;
      case Error.USER_NO_PERMISSION_RELEASE_NOTES: messageKey = 'error.USER_NO_PERMISSION_RELEASE_NOTES'; break;
      case Error.ADMIN_USER_NOT_ADMIN: messageKey = 'error.ADMIN_USER_NOT_ADMIN'; break;
      case Error.USERGROUP_FETCH_FAILED: messageKey = 'error.USERGROUP_FETCH_FAILED'; break;
      case Error.USER_FORBID_PASSWORD_CHANGE: messageKey = 'error.USER_FORBID_PASSWORD_CHANGE'; break;
      case Error.USER_ALREADY_EXISTS: messageKey = 'error.USER_ALREADY_EXISTS'; break;
      case Error.USER_ACCOUNT_EXPIRED: messageKey = 'error.USER_ACCOUNT_EXPIRED'; break;
      case Error.USER_ALREADY_DELETED: messageKey = 'error.USER_ALREADY_DELETED'; break;
      case Error.USER_DELETE_LOGGEDIN_TOREPRO: messageKey = 'error.USER_DELETE_LOGGEDIN_TOREPRO'; break;
      case Error.TOO_MANY_REQUESTS_EXCEPTION: messageKey = 'error.TOO_MANY_REQUESTS_EXCEPTION'; break;
      case Error.LIMIT_EXCEEDED_EXCEPTION: messageKey = 'error.LIMIT_EXCEEDED_EXCEPTION'; break;
      case Error.USER_DISABLE_LOGGEDIN_TOREPRO: messageKey = 'error.USER_DISABLE_LOGGEDIN_TOREPRO'; break;
      case Error.USER_NOT_ACTIVE: messageKey = 'error.USER_NOT_ACTIVE'; break;

      case Error.IOT_PAIRING_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_PAIRING_MESSAGE_SEND_FAILED'; break;
      case Error.IOT_INQUIRY_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_INQUIRY_MESSAGE_SEND_FAILED'; break;
      case Error.IOT_REPRO_START_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_REPRO_START_MESSAGE_SEND_FAILED'; break;
      case Error.IOT_IGON_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_IGON_MESSAGE_SEND_FAILED'; break;
      case Error.IOT_LATEST_REPRO_STATE_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_LATEST_REPRO_STATE_MESSAGE_SEND_FAILED'; break;
      case Error.IOT_DISCONNECT_MESSAGE_SEND_FAILED: messageKey = 'error.IOT_DISCONNECT_MESSAGE_SEND_FAILED'; break;

      case Error.DB_USER_INFO_NOT_FOUND: messageKey = 'error.DB_USER_INFO_NOT_FOUND'; break;
      case Error.DB_PAIRING_INFO_NOT_FOUND: messageKey = 'error.DB_PAIRING_INFO_NOT_FOUND'; break;
      case Error.DB_PAIRING_INFO_UPDATE_FAILED: messageKey = 'error.DB_PAIRING_INFO_UPDATE_FAILED'; break;
      case Error.DB_PFC_FILE_INFO_NOT_FOUND: messageKey = 'error.DB_PFC_FILE_INFO_NOT_FOUND'; break;
      case Error.DB_SYSTEM_INFO_NOT_FOUND: messageKey = 'error.DB_SYSTEM_INFO_NOT_FOUND'; break;
      case Error.DB_CLOUD_CONNECTOR_INFO_NOT_FOUND: messageKey = 'error.DB_CLOUD_CONNECTOR_INFO_NOT_FOUND'; break;
      case Error.DB_SYSTEM_HISTORY_INFO_NOT_FOUND: messageKey = 'error.DB_SYSTEM_HISTORY_INFO_NOT_FOUND'; break;
      case Error.DB_SYSTEM_RESPONSE_INFO_NOT_FOUND: messageKey = 'error.DB_SYSTEM_RESPONSE_INFO_NOT_FOUND'; break;
      case Error.DB_CSV_ANALYSIS_INFO_NOT_FOUND: messageKey = 'error.DB_CSV_ANALYSIS_INFO_NOT_FOUND'; break;
      case Error.DB_COMPANIES_INFO_NOT_FOUND: messageKey = 'error.DB_COMPANIES_INFO_NOT_FOUND'; break;
      case Error.DB_EXCLUDED_ECU_INFO_NOT_FOUND: messageKey = 'error.DB_EXCLUDED_ECU_INFO_NOT_FOUND'; break;
      case Error.DB_COMPANY_DEPARTMENT_INFO_FETCH_FAILED: messageKey = 'error.DB_COMPANY_DEPARTMENT_INFO_FETCH_FAILED'; break;
      case Error.DB_COMPANY_ALREADY_USED: messageKey = 'error.DB_COMPANY_ALREADY_USED'; break;
      case Error.DB_COMPANY_ALREADY_EXISTS: messageKey = 'error.DB_COMPANY_ALREADY_EXISTS'; break;
      case Error.DB_DEPARTMENT_ALREADY_EXISTS: messageKey = 'error.DB_DEPARTMENT_ALREADY_EXISTS'; break;
      case Error.DB_DEPARTMENT_USED_BY_USER: messageKey = 'error.DB_DEPARTMENT_USED_BY_USER'; break;
      case Error.DB_ECU_SYSTEMS_NOT_FOUND: messageKey = 'error.DB_ECU_SYSTEMS_NOT_FOUND'; break;
      case Error.DB_ECU_SYSTEMS_FETCH_FAILED: messageKey = 'error.DB_ECU_SYSTEMS_FETCH_FAILED'; break;
      case Error.DB_COUNTRY_ALREADY_EXISTS: messageKey = 'error.DB_COUNTRY_ALREADY_EXISTS'; break;
      case Error.DB_COUNTRY_NOT_FOUND: messageKey = 'error.DB_COUNTRY_NOT_FOUND'; break;
      case Error.DB_TARGET_UNIT_NOT_FOUND: messageKey = 'error.DB_TARGET_UNIT_NOT_FOUND'; break;
      case Error.DB_ENGINE_NOT_FOUND: messageKey = 'error.DB_ENGINE_NOT_FOUND'; break;
      case Error.DB_ASPIRATION_NOT_FOUND: messageKey = 'error.DB_ASPIRATION_NOT_FOUND'; break;
      case Error.DB_TRANSMISSION_NOT_FOUND: messageKey = 'error.DB_TRANSMISSION_NOT_FOUND'; break;
      case Error.DB_REPRO_FACTORS_NOT_FOUND: messageKey = 'error.DB_REPRO_FACTORS_NOT_FOUND'; break;
      case Error.DB_SHOP_ALREADY_EXISTS: messageKey = 'error.DB_SHOP_ALREADY_EXISTS'; break;
      case Error.DB_SHOP_INFO_NOT_FOUND: messageKey = 'error.DB_SHOP_INFO_NOT_FOUND'; break;
      case Error.DB_SHOP_ALREADY_USED: messageKey = 'error.DB_SHOP_ALREADY_USED'; break;
      case Error.DB_OBJECT_MOVEMENT_IN_PROGRESS: messageKey = 'error.DB_OBJECT_MOVEMENT_IN_PROGRESS'; break;
      case Error.DB_COMPANY_CANNOT_MOVE: messageKey = 'error.DB_COMPANY_CANNOT_MOVE'; break;
      case Error.DB_SHOP_CANNOT_MOVE: messageKey = 'error.DB_SHOP_CANNOT_MOVE'; break;
      case Error.DB_USER_CANNOT_MOVE: messageKey = 'error.DB_USER_CANNOT_MOVE'; break;
      case Error.DB_VEHICLE_MODEL_NOT_FOUND: messageKey = 'error.DB_VEHICLE_MODEL_NOT_FOUND'; break;
      case Error.DB_CANNOT_UPDATE_OLD_DATA: messageKey = 'error.DB_CANNOT_UPDATE_OLD_DATA'; break;
      case Error.DB_UPLOAD_STATUS_NOT_FOUND: messageKey = 'error.DB_UPLOAD_STATUS_NOT_FOUND'; break;
      case Error.DB_PFC_FILE_DETAILS_NOT_FOUND: messageKey = 'error.DB_PFC_FILE_DETAILS_NOT_FOUND'; break;
      case Error.DB_PFC_FILE_DETAILS_UPDATE_FAILED: messageKey = 'error.DB_PFC_FILE_DETAILS_UPDATE_FAILED'; break;
      case Error.DB_PFC_FILE_DETAILS_DELETE_FAILED: messageKey = 'error.DB_PFC_FILE_DETAILS_DELETE_FAILED'; break;
      case Error.DB_PFC_APPROVAL_NOT_FOUND: messageKey = 'error.DB_PFC_APPROVAL_NOT_FOUND'; break;
      case Error.DB_PFC_APPROVAL_UPDATE_FAILED: messageKey = 'error.DB_PFC_APPROVAL_UPDATE_FAILED'; break;
      case Error.DB_PFC_APPROVAL_DELETE_FAILED: messageKey = 'error.DB_PFC_APPROVAL_DELETE_FAILED'; break;
      case Error.DB_PFC_FILE_ALREADY_PUBLISHED: messageKey = 'error.DB_PFC_FILE_ALREADY_PUBLISHED'; break;
      case Error.DB_REPRO_TARGET_UNIT_NOT_FOUND: messageKey = 'error.DB_REPRO_TARGET_UNIT_NOT_FOUND'; break;
      case Error.CLOUD_CONNECTOR_VERSION_WRONG: messageKey = 'error.CLOUD_CONNECTOR_VERSION_WRONG'; break;
      case Error.DB_COUNTRY_GROUP_ALREADY_EXISTS: messageKey = 'error.DB_COUNTRY_GROUP_ALREADY_EXISTS'; break;
      case Error.DB_TARGET_UNIT_ALREADY_EXISTS: messageKey = 'error.DB_TARGET_UNIT_ALREADY_EXISTS'; break;
      case Error.DB_ENGINE_ALREADY_EXISTS: messageKey = 'error.DB_ENGINE_ALREADY_EXISTS'; break;
      case Error.DB_ASPIRATION_ALREADY_EXISTS: messageKey = 'error.DB_ASPIRATION_ALREADY_EXISTS'; break;
      case Error.DB_TRANSMISSION_ALREADY_EXISTS: messageKey = 'error.DB_TRANSMISSION_ALREADY_EXISTS'; break;
      case Error.DB_REPRO_FACTORS_ALREADY_EXISTS: messageKey = 'error.DB_REPRO_FACTORS_ALREADY_EXISTS'; break;
      case Error.DB_COMPANY_CODE_ALREADY_EXISTS: messageKey = 'error.DB_COMPANY_CODE_ALREADY_EXISTS'; break;
      case Error.DB_SHOP_CODE_ALREADY_EXISTS: messageKey = 'error.DB_SHOP_CODE_ALREADY_EXISTS'; break;
      case Error.DB_VEHICLE_MODEL_DISPLAY_ALREADY_EXISTS: messageKey = 'error.DB_VEHICLE_MODEL_DISPLAY_ALREADY_EXISTS'; break;
      case Error.DB_VEHICLE_MODEL_DISPLAY_NOT_FOUND: messageKey = 'error.DB_VEHICLE_MODEL_DISPLAY_NOT_FOUND'; break;
      case Error.DB_VEHICLE_MODEL_DISPLAY_ALREADY_USED: messageKey = 'error.DB_VEHICLE_MODEL_DISPLAY_ALREADY_USED'; break;
      case Error.DB_PFC_FILE_COUNTRY_GROUP_ALREADY_EXISTS: messageKey = 'error.DB_PFC_FILE_COUNTRY_GROUP_ALREADY_EXISTS'; break;
      case Error.DB_CANID_MODEL_NOT_FOUND: messageKey = 'error.DB_CANID_MODEL_NOT_FOUND'; break;
      case Error.DB_CANID_MODEL_ALREADY_EXISTS: messageKey = 'error.DB_CANID_MODEL_ALREADY_EXISTS'; break;
      case Error.DB_PFC_FILE_TARGET_ID_NOT_MATCH: messageKey = 'error.DB_PFC_FILE_TARGET_ID_NOT_MATCH'; break;
      case Error.DB_PFC_FILE_INVALID_FOR_TWO_STEP_PAIRING: messageKey = 'error.DB_PFC_FILE_INVALID_FOR_TWO_STEP_PAIRING'; break;
      case Error.DB_PFC_APPROVAL_NOT_ALLOW: messageKey = 'error.DB_PFC_APPROVAL_NOT_ALLOW'; break;
      case Error.DB_PFC_APPROVAL_NOT_ALLOW_DISTRIBUTOR_ONLY: messageKey = 'error.DB_PFC_APPROVAL_NOT_ALLOW_DISTRIBUTOR_ONLY'; break;
      case Error.PFC_FILE_SAME_SYSTEM_IN_BATCH_UPLOAD: messageKey = 'error.PFC_FILE_SAME_SYSTEM_IN_BATCH_UPLOAD'; break;
      // 特殊対応 getErrorMessageForCodeでactionメッセージを使用しているが、
      // Frontend側で吸収してエラーメッセージを表示するように対応している。
      case Error.DB_PFC_FILE_ALREADY_REPROGRAM: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_ID_NEW'; break;

      case Error.CLOUD_CONNECTOR_NOT_ACTIVE: messageKey = 'error.CLOUD_CONNECTOR_NOT_ACTIVE'; break;
      case Error.CLOUD_CONNECTOR_USEDBY_OTHER: messageKey = 'error.CLOUD_CONNECTOR_USEDBY_OTHER'; break;
      case Error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED: messageKey = 'error.CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED'; break;
      case Error.CLOUD_CONNECTOR_INQUIRY_RSP_WRONG: messageKey = 'error.CLOUD_CONNECTOR_INQUIRY_RSP_WRONG'; break;
      case Error.CLOUD_CONNECTOR_INQUIRY_VEHICLE_ALREADY_UPDATED: messageKey = 'error.CLOUD_CONNECTOR_INQUIRY_VEHICLE_ALREADY_UPDATED'; break;
      case Error.CLOUD_CONNECTOR_INQUIRY_INVALID_TARGET_ECU: messageKey = 'error.CLOUD_CONNECTOR_INQUIRY_INVALID_TARGET_ECU'; break;
      case Error.CLOUD_CONNECTOR_INQUIRY_RSP_NOT_RECEIVED: messageKey = 'error.CLOUD_CONNECTOR_INQUIRY_RSP_NOT_RECEIVED'; break;
      case Error.CLOUD_CONNECTOR_VEHICLE_COMMUNICATION_ERROR: messageKey = 'error.CLOUD_CONNECTOR_VEHICLE_COMMUNICATION_ERROR'; break;
      case Error.CLOUD_CONNECTOR_INTERNAL_ERROR: messageKey = 'error.CLOUD_CONNECTOR_INTERNAL_ERROR'; break;
      case Error.CLOUD_CONNECTOR_USER_NOT_PAIRED: messageKey = 'error.CLOUD_CONNECTOR_USER_NOT_PAIRED'; break;
      case Error.CLOUD_CONNECTOR_PFC_FILE_DOWNLOAD_FAILED: messageKey = 'error.CLOUD_CONNECTOR_PFC_FILE_DOWNLOAD_FAILED'; break;
      case Error.CLOUD_CONNECTOR_REPRO_EXECUTION_INPROGRESS: messageKey = 'error.CLOUD_CONNECTOR_REPRO_EXECUTION_INPROGRESS'; break;
      case Error.CLOUD_CONNECTOR_INVALID_J2534_DEVICE: messageKey = 'error.CLOUD_CONNECTOR_INVALID_J2534_DEVICE'; break;
      case Error.CLOUD_CONNECTOR_SEQUENCE_APP_NOT_FOUND: messageKey = 'error.CLOUD_CONNECTOR_SEQUENCE_APP_NOT_FOUND'; break;
      case Error.CLOUD_CONNECTOR_SEQUENCE_APP_INIT_FAILED: messageKey = 'error.CLOUD_CONNECTOR_SEQUENCE_APP_INIT_FAILED'; break;
      case Error.CLOUD_CONNECTOR_REPRO_EXECUTE_FAILED: messageKey = 'error.CLOUD_CONNECTOR_REPRO_EXECUTE_FAILED'; break;
      case Error.CLOUD_CONNECTOR_J2534_DEVICE_OPEN_FAILED: messageKey = 'error.CLOUD_CONNECTOR_J2534_DEVICE_OPEN_FAILED'; break;
      case Error.CLOUD_CONNECTOR_J2534_DEVICE_CONNECT_FAILED: messageKey = 'error.CLOUD_CONNECTOR_J2534_DEVICE_CONNECT_FAILED'; break;
      case Error.CLOUD_CONNECTOR_DISCONNECT_NETWORK: messageKey = 'error.CLOUD_CONNECTOR_DISCONNECT_NETWORK'; break;
      case Error.CLOUD_CONNECTOR_READ_RXSWIN_FAILED: messageKey = 'error.CLOUD_CONNECTOR_READ_RXSWIN_FAILED'; break;
      case Error.CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED: messageKey = 'error.CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED'; break;

      case Error.PFC_FILE_UPLOAD_FAILED: messageKey = 'error.PFC_FILE_UPLOAD_FAILED'; break;
      case Error.PFC_FILE_ROOT_FOLDER_CREATE_FAILED: messageKey = 'error.PFC_FILE_ROOT_FOLDER_CREATE_FAILED'; break;
      case Error.PFC_FILE_DOWNLOAD_FAILED: messageKey = 'error.PFC_FILE_DOWNLOAD_FAILED'; break;
      case Error.PFC_FILE_UNZIP_FAILED: messageKey = 'error.PFC_FILE_UNZIP_FAILED'; break;
      case Error.PFC_FILE_CONTENT_EMPTY: messageKey = 'error.PFC_FILE_CONTENT_EMPTY'; break;
      case Error.PFC_FILE_NO_CSV_FILE: messageKey = 'error.PFC_FILE_NO_CSV_FILE'; break;
      case Error.PFC_FILE_CSV_PARSING_FAILED: messageKey = 'error.PFC_FILE_CSV_PARSING_FAILED'; break;
      case Error.PFC_FILE_INVALID_CSV_FILE: messageKey = 'error.PFC_FILE_INVALID_CSV_FILE'; break;
      case Error.PFC_FILE_INVALID_PFC_FILE: messageKey = 'error.PFC_FILE_INVALID_PFC_FILE'; break;
      case Error.PFC_FILE_INVALID_MOT_FILE: messageKey = 'error.PFC_FILE_INVALID_MOT_FILE'; break;
      case Error.PFC_FILE_LOCK_FAILED: messageKey = 'error.PFC_FILE_LOCK_FAILED'; break;
      case Error.TEST_PFC_CANT_PUBLISHED: messageKey = 'error.TEST_PFC_CANT_PUBLISHED'; break;
      case Error.PFC_FILE_INCLUDE_LOOP_INFORMATION: messageKey = 'error.PFC_FILE_INCLUDE_LOOP_INFORMATION'; break;
      case Error.PFC_FILE_MULTIPLE_NEW_ID_FOR_TARGET_ID: messageKey = 'error.PFC_FILE_MULTIPLE_NEW_ID_FOR_TARGET_ID'; break;
      case Error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO: messageKey = 'error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO'; break;
      case Error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED: messageKey = 'error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED'; break;
      case Error.PFC_FILE_EYE_SYSTEM_ONLY_SINGLE_SYSTEM: messageKey = 'error.PFC_FILE_EYE_SYSTEM_ONLY_SINGLE_SYSTEM'; break;
      case Error.PFC_FILE_TWO_STEP_TEST_PFC_NOT_SUPPORTED: messageKey = 'error.PFC_FILE_TWO_STEP_TEST_PFC_NOT_SUPPORTED'; break;
      case Error.PFC_FILE_TWO_STEP_MULTIPLE_SYSTEMS_NOT_SUPPORTED: messageKey = 'error.PFC_FILE_TWO_STEP_MULTIPLE_SYSTEMS_NOT_SUPPORTED'; break;
      case Error.PFC_FILE_TWO_STEP_SYSTEMS_NOT_SUPPORTED: messageKey = 'error.PFC_FILE_TWO_STEP_SYSTEMS_NOT_SUPPORTED'; break;
      case Error.PFC_FILE_TWO_STEP_MULTIPLE_GROUPS_NOT_SUPPORTED: messageKey = 'error.PFC_FILE_TWO_STEP_MULTIPLE_GROUPS_NOT_SUPPORTED'; break;
      case Error.PFC_FILE_TWO_STEP_MASTER_CHILD_SYSTEM_NOT_MATCHING: messageKey = 'error.PFC_FILE_TWO_STEP_MASTER_CHILD_SYSTEM_NOT_MATCHING'; break;
      case Error.PFC_FILE_TWO_STEP_MASTER_CHILD_TARGETS_NOT_MATCHING: messageKey = 'error.PFC_FILE_TWO_STEP_MASTER_CHILD_TARGETS_NOT_MATCHING'; break;
      case Error.PFC_FILE_TWO_STEP_VIA_ID_REPEATED_IN_NEW_PFC: messageKey = 'error.PFC_FILE_TWO_STEP_VIA_ID_REPEATED_IN_NEW_PFC'; break;
      case Error.PFC_FILE_NOT_ALL_NEWID_MATCHED: messageKey = 'error.PFC_FILE_NOT_ALL_NEWID_MATCHED'; break;
      case Error.PFC_FILE_SYSTEM_NAME_IS_NOT_CCU: messageKey = 'error.PFC_FILE_SYSTEM_NAME_IS_NOT_CCU'; break;
      case Error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO_PUBLISH: messageKey = 'error.PFC_FILE_TREE_MORE_THAN_ONCE_REPRO_PUBLISH'; break;
      case Error.PFC_FILE_NOT_MATCHED_TARGETID_IN_ALL_FILES: messageKey = 'error.PFC_FILE_NOT_MATCHED_TARGETID_IN_ALL_FILES'; break;
      case Error.PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID: messageKey = 'error.PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID'; break;
      case Error.PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE: messageKey = 'error.PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE'; break;
      case Error.PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE: messageKey = 'error.PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE'; break;
      case Error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_PUBLISH: messageKey = 'error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_PUBLISH'; break;
      case Error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_PUBLISH: messageKey = 'error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_PUBLISH'; break;
      case Error.PFC_FILE_REPRO_TYPE_NOT_SET_PUBLISH: messageKey = 'error.PFC_FILE_REPRO_TYPE_NOT_SET_PUBLISH'; break;
      case Error.PFC_FILE_NEW_ID_REPEATED_PUBLISH: messageKey = 'error.PFC_FILE_NEW_ID_REPEATED_PUBLISH'; break;
      case Error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_NOT_CONTAINS_ONLY_FORCE_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_NOT_CONTAINS_ONLY_FORCE_ENABLED_NEW_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_BASE_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_BASE_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_NEW_ID_REPEATED_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_NEW_ID_REPEATED_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_ALREADY_PUBLISHED_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_ALREADY_PUBLISHED_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_NOT_CONTAINS_IN_SUB_GROUP_NEW_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_NOT_CONTAINS_IN_SUB_GROUP_NEW_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_EXIT_ONLY_EYE_TWO: messageKey = 'error.PFC_FILE_EXIT_ONLY_EYE_TWO'; break;
      case Error.PFC_FILE_CONTAINS_EYE_TWO_ID_ALL_ZERO: messageKey = 'error.PFC_FILE_CONTAINS_EYE_TWO_ID_ALL_ZERO'; break;
      case Error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_PUBLISH: messageKey = 'error.PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_PUBLISH'; break;
      case Error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES: messageKey = 'error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES'; break;
      case Error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE: messageKey = 'error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE'; break;
      case Error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES: messageKey = 'error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES'; break;
      case Error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID: messageKey = 'error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID'; break;
      case Error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID: messageKey = 'error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID'; break;
      case Error.PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID: messageKey = 'error.PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID'; break;
      case Error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE'; break;
      case Error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_PUBLISH: messageKey = 'error.PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_PUBLISH'; break;
      case Error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_PUBLISH: messageKey = 'error.PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_PUBLISH'; break;
      case Error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_PUBLISH: messageKey = 'error.PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_PUBLISH'; break;
      case Error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_PUBLISH: messageKey = 'error.PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_PUBLISH'; break;
      case Error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_PUBLISH: messageKey = 'error.PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_PUBLISH'; break;
      case Error.PFC_FILE_TWO_STEP_VIA_ID_REPEATED_IN_NEW_PFC_REPRO_TYPE_CHANGE: messageKey = 'error.PFC_FILE_TWO_STEP_VIA_ID_REPEATED_IN_NEW_PFC_REPRO_TYPE_CHANGE'; break;
      case Error.MERCHANDISE_INVALID_PASSWORD: messageKey = 'error.MERCHANDISE_INVALID_PASSWORD'; break;
      case Error.MERCHANDISE_CANNOT_SET_PASSWORD_USING_SERIAL_NUMBER: messageKey = 'error.MERCHANDISE_CANNOT_SET_PASSWORD_USING_SERIAL_NUMBER'; break;

      case Error.IMPORT_CSV_FILE_FORMAT_INVALID: messageKey = 'error.IMPORT_CSV_FILE_FORMAT_INVALID'; break;
      case Error.TELEMATICS_ECU_NOT_INSTALLED: messageKey = 'error.TELEMATICS_ECU_NOT_INSTALLED'; break;
      case Error.IMPORT_CSV_FILE_HAS_MORE_RECORDS: messageKey = 'error.IMPORT_CSV_FILE_HAS_MORE_RECORDS'; break;
      case Error.IMPORT_CSV_FILE_HAS_INVALID_INFORMATION: messageKey = 'error.IMPORT_CSV_FILE_HAS_INVALID_INFORMATION'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_EMPTY: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_EMPTY'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_LENGTH: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_LENGTH'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_EMAIL: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_EMAIL'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_LANGUAGE_INVALID: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_LANGUAGE_INVALID'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_AUTH_GROUP_INVALID: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_AUTH_GROUP_INVALID'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_ROOT_AUTH_GROUP_INVALID: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_ROOT_AUTH_GROUP_INVALID'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_SHOP_NOT_EXISTS: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_SHOP_NOT_EXISTS'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_NAME_INVALID: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_NAME_INVALID'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_SHOP_NAME_INVALID: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_SHOP_NAME_INVALID'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_USER_ALREADY_EXISTS: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_USER_ALREADY_EXISTS'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_USER_NOT_FOUND: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_USER_NOT_FOUND'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_USER_CANT_MOVE: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_USER_CANT_MOVE'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_DUPLICATED_RECORD: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_DUPLICATED_RECORD'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_CHARACTERS: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_INVALID_CHARACTERS'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_SHOP_ALREADY_DELEGATED: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_SHOP_ALREADY_DELEGATED'; break;
      case Error.IMPORT_CSV_FILE_COLUMN_DATA_MISSING_HEADER: messageKey = 'error.IMPORT_CSV_FILE_COLUMN_DATA_MISSING_HEADER'; break;
      case Error.EXPORT_EVENT_LOG_NOT_FOUND: messageKey = 'error.EXPORT_EVENT_LOG_NOT_FOUND'; break;
      case Error.PFC_JSON_FILE_CREATION_FAILED: messageKey = 'error.PFC_JSON_FILE_CREATION_FAILED'; break;
      case Error.DB_NOT_FOUND_MANUAL_FILE: messageKey = 'error.DB_NOT_FOUND_MANUAL_FILE'; break;
      case Error.MAC_KEY_SEQUENCE_NOT_FOUND: messageKey = 'error.MAC_KEY_SEQUENCE_NOT_FOUND'; break;
      case Error.IMB_SEQUENCE_NOT_FOUND: messageKey = 'error.IMB_SEQUENCE_NOT_FOUND'; break;
      case Error.SOLD_VEHICLE_PAK_FILE_DOWNLOAD_FAILED: messageKey = 'error.FAILED_PAK_FILE_DOWNLOAD'; break;
      case Error.SOLD_VEHICLE_FLASH_WRITE_ALREADY_STARTED: messageKey = 'error.FAILED_FLASH_WRITE_ALREADY_LAUNCHED'; break;
      case Error.SOLD_VEHICLE_FLASH_WRITE_NOT_INSTALLED: messageKey = 'error.FAILED_FLASH_WRITE_NOT_INSTALLED'; break;
      case Error.SOLD_VEHICLE_FLASH_WRITE_LAUNCH_FAILED: messageKey = 'error.FAILED_FLASH_WRITE_LAUNCH_FAILED'; break;
      case Error.PLAN_IN_USE: messageKey = 'error.PLAN_IN_USE'; break;
      case Error.PLAN_ALREADY_ABOLISHED: messageKey = 'error.PLAN_ALREADY_ABOLISHED'; break;
      case Error.PLAN_CANNOT_BE_PRIVATE: messageKey = 'error.PLAN_CANNOT_BE_PRIVATE'; break;
      case Error.PLAN_CANNOT_BE_CHANGED_USER_SIGNED_IN: messageKey = 'error.PLAN_CANNOT_BE_CHANGED_USER_SIGNED_IN'; break;
      case Error.IMPORT_CSV_FILE_INVALID_EXTENSION: messageKey = 'error.IMPORT_CSV_FILE_INVALID_EXTENSION'; break;
      case Error.IMPORT_CSV_FILE_INVALID_HEADER: messageKey = 'error.IMPORT_CSV_FILE_INVALID_HEADER'; break;
      case Error.IMPORT_CSV_FILE_NO_DATA: messageKey = 'error.IMPORT_CSV_FILE_NO_DATA'; break;
      case Error.IMPORT_CSV_FILE_INVALID_DATA: messageKey = 'error.IMPORT_CSV_FILE_INVALID_DATA'; break;
      case Error.IMPORT_CSV_FILE_INVALID_COUNTRY: messageKey = 'error.IMPORT_CSV_FILE_INVALID_COUNTRY'; break;
      case Error.IMPORT_CSV_FILE_INVALID_DUPLICATED_ID: messageKey = 'error.IMPORT_CSV_FILE_INVALID_DUPLICATED_ID'; break;
      case Error.PAK_FILE_INVALID_EXTENSION: messageKey = 'error.PAK_FILE_INVALID_EXTENSION'; break;
      case Error.PAK_FILE_NO_DATA_WITH_MATCHING_PART_NUMBER: messageKey = 'error.PAK_FILE_NO_DATA_WITH_MACHING_PART_NUMBER'; break;
      case Error.PAK_FILE_UPLOAD_FAILED: messageKey = 'error.PAK_FILE_UPLOAD_FAILED'; break;
      case Error.MERCHANDISE_ALREADY_REGISTER_CODE: messageKey = 'error.MERCHANDISE_ALREADY_REGISTER_CODE'; break;
      case Error.MERCHANDISE_NOT_FOUND_CODE: messageKey = 'error.MERCHANDISE_NOT_FOUND_CODE'; break;
      case Error.DB_PACK_FILE_INFO_NOT_FOUND: messageKey = 'error.DB_PACK_FILE_INFO_NOT_FOUND'; break;
      case Error.DB_PACK_FILE_INFO_DELETE_FAILED: messageKey = 'error.DB_PACK_FILE_INFO_DELETE_FAILED'; break;
      case Error.PACK_FILE_DELETE_FAILED: messageKey = 'error.PACK_FILE_DELETE_FAILED'; break;
      // 予期せぬ例外の場合、こちらに追記する
      case Error.INTERNAL_SERVER_ERROR:
      case Error.AWS_EXCEPTION:
        messageKey = 'error.INTERNAL_SERVER_ERROR';
        break;
      default: messageKey = `not set error code: ${code}`;
    }
    return messageKey;
  },

  /* eslint-disable-next-line */
  getCauseAndActionForCode(code) {
    let messageKey = '';
    switch (code) {
      case Error.SEQUENCE_APP_ERR_INIT: messageKey = 'action.SEQUENCE_APP_ERR_INIT'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_ID: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_ID'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_ID_NEW: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_ID_NEW'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_ID_COMMINICATION: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_ID_COMMINICATION'; break;
      case Error.SEQUENCE_APP_ERR_FORCED_WRITE: messageKey = 'action.SEQUENCE_APP_ERR_FORCED_WRITE'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_ENGINE_RPM: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_ENGINE_RPM'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_SPEED: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_SPEED'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_VOLTAGE: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_VOLTAGE'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_IDLING_STOP: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_IDLING_STOP'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CHANGE_GWMODE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CHANGE_GWMODE'; break;
      case Error.SEQUENCE_APP_ERR_NOT_ALLOWED: messageKey = 'action.SEQUENCE_APP_ERR_NOT_ALLOWED'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_ROM_DELETE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_ROM_DELETE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_DOWNLOAD: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_DOWNLOAD'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_COMPLETE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_COMPLETE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SUM_REQUEST: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SUM_REQUEST'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_SUM: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_SUM'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_ID_AFTER_WRITE: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_ID_AFTER_WRITE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SECURITY_TIMEOUT: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SECURITY_TIMEOUT'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_EXTERNAL_TIMEOUT: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_EXTERNAL_TIMEOUT'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_EXTERNAL_ERROR: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_EXTERNAL_ERROR'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_TIMEOUT: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_TIMEOUT'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_IGOFFON_ERROR: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_IGOFFON_ERROR'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_OPEN_DEVICE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_OPEN_DEVICE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CONNECT_DEVICE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CONNECT_DEVICE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DIAG_COMMINICATION: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DIAG_COMMINICATION'; break;
      case Error.SEQUENCE_APP_ERR_SYSTEM_ERROR: messageKey = 'action.SEQUENCE_APP_ERR_SYSTEM_ERROR'; break;
      case Error.SEQUENCE_APP_ERR_JSON_FILE_INCORRECT: messageKey = 'action.SEQUENCE_APP_ERR_JSON_FILE_INCORRECT'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SEED: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SEED'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_ERROR: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_ERROR'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_TIMEOUT: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_TIMEOUT'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_KEY_OPEN: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_KEY_OPEN'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SID_UNFILTER: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SID_UNFILTER'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SECURITY_CLOUD_ERROR: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SECURITY_CLOUD_ERROR'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_SEED: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_SEED'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_KEY_OPEN: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_KEY_OPEN'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SWITCH_DIAG_SESSION: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SWITCH_DIAG_SESSION'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_START_REPROGRAMMING: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_START_REPROGRAMMING'; break;
      case Error.SEQUENCE_APP_ERR_ECU_STATE_INITIALIZING: messageKey = 'action.SEQUENCE_APP_ERR_ECU_STATE_INITIALIZING'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_WRITE_ADDRESS_OFFSET: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_WRITE_ADDRESS_OFFSET'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_WRITE_KEY_SEED: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_WRITE_KEY_SEED'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_WRITE_NONCE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_WRITE_NONCE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_DELETE_ROUTINE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_DELETE_ROUTINE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_DELETE_ROUTINE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_DELETE_ROUTINE'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_DELETE_ROUTINE: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_DELETE_ROUTINE'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_KEY_VERIFICATION: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_KEY_VERIFICATION'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_MEMORY_CLEAR_AREA: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_MEMORY_CLEAR_AREA'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_REPRO_DATA: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_REPRO_DATA'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_REPRO_DATA: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_REPRO_DATA'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_REPRO_DATA: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_REPRO_DATA'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CHECK_REWRITE_DATA_AREA: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CHECK_REWRITE_DATA_AREA'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_SERVER_RESET: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_SERVER_RESET'; break;
      case Error.SEQUENCE_APP_ERR_CANNOT_CONFIRM_VEHICLE_STATUS: messageKey = 'action.SEQUENCE_APP_ERR_CANNOT_CONFIRM_VEHICLE_STATUS'; break;
      case Error.SEQUENCE_APP_ERR_WRONG_AREA_INDICATED: messageKey = 'action.SEQUENCE_APP_ERR_WRONG_AREA_INDICATED'; break;
      default: messageKey = "";
    }
    return messageKey;
  },
};