/* **
 * ラベル用文字列取得
 * @param masterTranslations: 言語設定情報一覧
 * @param language: 取得したい対象言語
 * @param displayLang: 画面表示言語
 * @return: ラベル用文字列
 */
export const getLabel = (masterTranslations, language, displayLang) => {
  let ret = '';
  const i = masterTranslations.findIndex((lng) => lng.id === language);
  if (i !== -1) ret = masterTranslations[i].display[displayLang];
  return ret;
};

/* **
 * 選択済言語取得
 * @param translationList: 言語ごとのデータ一覧
 * @param cachedTranslationLang: キャッシュ済選択言語
 * @param displayLang: 画面表示言語
 * @return: 選択言語
 */
export const extractSelectedLanguage = (translationList, cachedTranslationLang, displayLang) => {
  if (translationList.length === 0) {
    return null;
  }
  // キャッシュ済みの翻訳言語が存在するかチェック
  const index = translationList.findIndex((lng) => lng.language === cachedTranslationLang);
  if (index !== -1) {
    return cachedTranslationLang;
  }
  else {
    // キャッシュ済み情報がない場合は翻訳言語内に display の言語が存在するかチェック 
    const index = translationList.findIndex((lng) => lng.language === displayLang);
    if (index !== -1) {
      return displayLang;
    }
    else {
      // キャッシュ済み情報がなくdisplay言語が存在しない場合は default 言語で表示する 
      const index = translationList.findIndex((lng) => lng.is_default === true);
      if (index !== -1) {
        return translationList[index].language;
      }
      else {
        // 上記の状態でマッチしない場合はリストの先頭を表示する 
        return translationList[0].language;
      }
    }
  }
};

/* **
 * Get separator symbol for the service news.
 */
const getServiceNewsSeparator = () => {
  return ' | ';
};

export default {
  getLabel,
  extractSelectedLanguage,
  getServiceNewsSeparator
};