// State class for login user information.
import api from '@/common/api';
import config from '../config';
import handler from '../util/apiResultValidator';

export default {
  namespaced: true,

  state: {
    userId: null,
    userName: null,
    group: null,
    oidGroup: null,
    isAgreement: false,
    isAgreementPersonalData: false,
    language: 'en',
    isDelegated: true,
    isAlongToCompany: false,
    companyId: null,
    shopId: null,
    lastPairingNetAppId: null,
    region: null,
    userInfo: {}, // 上記の情報をまとめた情報
    userCompanyInfo: null, // ログインしている会社のユーザー情報
    userCache: {}, // ユーザーに紐づけてキャッシュしたい諸項目
    signoutConfig: {},
    hasSubscriber: false,
    isTechnicalHq: false,
  },

  mutations: {

    setUserProperty(state, payload) {
      state.userId = payload.userId;
      state.userName = payload.userName;
      state.group = payload.group;
      state.oidGroup = payload.oidGroup;
      state.isAgreement = payload.isAgreement;
      state.isAgreementPersonalData = payload.isAgreementPersonalData;
      state.language = payload.language;
      state.companyId = payload.companyId;
      state.shopId = payload.shopId;
      state.isDelegated = payload.isDelegated;
      state.lastPairingNetAppId = payload.lastPairingNetAppId;
      state.region = payload.region;
      state.hasSubscriber = payload.hasSubscriber;
      state.isTechnicalHq = payload.isTechnicalHq;  // TODO Backend側と属性情報の詳細につて調整

      // 会社ユーザーか店舗ユーザーか判定
      let isAlongToCompany = true;
      if (payload.companyId && payload.shopId) {
        // 両方値があれば 店舗ユーザー
        isAlongToCompany = false;
      }
      state.isAlongToCompany = isAlongToCompany;

      // 全情報を追加する
      state.userInfo = {
        userId: payload.userId,
        userName: payload.userName,
        group: payload.group,
        iodGroup: payload.oidGroup,
        isAgreement: payload.isAgreement,
        isAgreementPersonalData: payload.isAgreementPersonalData,
        language: payload.language,
        isDelegated: payload.isDelegated,
        isAlongToCompany,
        companyId: payload.companyId,
        shopId: payload.shopId,
        lastPairingNetAppId: payload.lastPairingNetAppId,
        region: payload.region,
        isActive: payload.isActive,
        isExternalAuth: payload.isExternalAuth,
        hasSubscriber: payload.hasSubscriber,
        isTechnicalHq: payload.isTechnicalHq
      };
    },

    setUserCompany(state, payload) {
      state.userCompanyInfo = payload;
    },

    setUserCache(state, payload) {
      state.userCache[payload.key] = payload.value;
    },

    initializeCache(state, payload) {
      state.userCache = payload;
    },
  },

  actions: {

    setUserProperty(context, loginResponse) {
      const userInfo =
      {
        userId: loginResponse.user_id,
        userName: loginResponse.user_name,
        group: loginResponse.group,
        oidGroup: loginResponse.oid_group,
        isAgreement: loginResponse.has_agreement,
        isAgreementPersonalData: loginResponse.has_personal_data_agreement ?? true,
        language: loginResponse.language,
        companyId: loginResponse.company_id,
        shopId: loginResponse.shop_id,
        isDelegated: loginResponse.is_delegated,
        lastPairingNetAppId: loginResponse.last_pairing_net_app_id,
        region: loginResponse.country_group,
        isActive: loginResponse.is_active,
        isExternalAuth: loginResponse.is_external_auth,
        hasSubscriber: loginResponse.has_subscriber,
        isTechnicalHq: loginResponse.is_tech_user
      };
      context.commit('setUserProperty', userInfo);
    },

    setUserCompany(context, payload) {
      context.commit('setUserCompany', payload);
    },

    setUserCache(context, payload) {
      context.commit('setUserCache', payload);
    },

    saveUserCache(context) {
      if (!context.state.userId) return;
      try {
        const params = { caches: JSON.stringify(context.state.userCache) };
        api.postCall(config.ADMIN, '/users/' + context.state.userId + '/caches', params);
      } catch (err) {
        // noop
      }
    },

    async loadUserCache(context) {
      if (!context.state.userId) return;
      const props = await api.getCall(config.ADMIN, '/users/' + context.state.userId + '/caches');
      handler.validate(handler.validateTypes.response, props, null, null, () => {
        try {
          const tmp = JSON.parse(props.data.caches);
          // キャッシュ内容が空、または正常にJSON変換されなかった場合は空オブジェクトで初期化
          if (!tmp || typeof tmp !== 'object')
            context.commit('initializeCache', {});
          else
            context.commit('initializeCache', tmp);
        } catch (_) {
          // 文字列⇒JSON 変換に失敗したらキャッシュ内容を空オブジェクトで初期化
          context.commit('initializeCache', {});
        }
      }, () => {
        // API からキャッシュ内容を正常に取得できなければ空オブジェクトで初期化
        context.commit('initializeCache', {});
      }, null, false);
    }
  }
};