<div class="container-fluid ml-n2">
  <div class="button-display text-right mt-3">
    <b-button
      v-if="displayReproTypeMode === 'campaign' && !isTestMode && (reprogramTableTypes.includes('update') || reprogramTableTypes.includes('restore'))"
      variant="primary" v-on:click="updateProgram()" size="sm">
      {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
    <span class="ml-3"></span>
    <div v-if="isUpdate">
      <div v-if="isVisible">
        <div v-if='isPublished'>
          <div>
            <b-button variant="primary" v-on:click="switchTable" size="sm">
              {{ $t('message.button_vehicle_system_show_unpublished') }}</b-button>
          </div>
          <div class="mt-2">
            <strong>{{ $t('message.label_vehicle_published') }}</strong>
          </div>
        </div>
        <div v-else>
          <div>
            <b-button variant="primary" v-on:click="switchTable" size="sm">
              {{ $t('message.button_vehicle_system_show_published') }}</b-button>
          </div>
          <div class="mt-2">
            <strong>{{ $t('message.label_vehicle_unpublished') }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- シリアルNo.確認ダイアログ -->
  <div>
    <b-modal v-model="showModalSerialNo" id="modal-pfc-file-serialNo-input" size="lg" header-bg-variant="secondary"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc
      @ok="checkPfcFileSerialNo()">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t("message.message_title_serial") }}
        </div>
      </template>
      <template v-slot:default>
        <div class="container-fluid mt-2 mb-2">
          <div class="row no-gutters">
            <!-- 説明文 -->
            <div class="mb-3">{{ $t('message.messege_repro_merchantability_password') }}</div>
            <!-- 入力フォーム -->
            <div class="col-12">
              <b-form-group class="form-group">
                <b-input-group>
                  <b-form-input type="text" v-model="pfcFileSerialNo" size="sm" autocomplete="off"
                  :formatter="serialNoFormatter">
                    {{ pfcFileSerialNo }}
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center w-100">
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="showModalSerialNo=false">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <b-button size="sm" variant="primary" @click="checkPfcFileSerialNo()" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <div class="text-left">
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc" v-show="isPublished" rowSelect
      :items="publishedTableItems" :columns="setupTableColumn()" class="mt-2 ml-2" type="simple" @rowClicked="clickRow"
      usePopup @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc" v-show="!isPublished && isVisible"
      rowSelect :items="unpublishedTableItems" :columns="setupTableColumn()" class="mt-2 ml-2" type="simple"
      @rowClicked="clickRow" usePopup @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
  </div>
  <div v-if="displayReproTypeMode !== 'campaign'" class="text-right">
    <!-- 戻るボタン -->
    <b-button variant="outline-primary" v-on:click="back()" class="ml-2 mr-2 mt-4" size="sm">
      {{ $t('message.common_button_back') }}
    </b-button>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>