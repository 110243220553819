<div class="text-left">
  <!-- 検索条件 -->
  <div v-if="isReproPossible()" class="col-12">
    <div class="file-search-container mt-3">
      <div class="row">
        <div class="col-12 text-left">
          <cstm-card headerTitle="message.header_file_search" noBorder :isCollapse="false">
            <template v-slot:body>
              <div class="col-12 text-left mt-3">
                <b-form class="d-flex w-100">
                  <!-- サービスニュースNo -->
                  <div class="w-30">
                    <b-form-group class="form-group">
                      <label v-if="displayReproTypeMode === 'campaign'" class="label-sm" for="service_news">{{
                        $t("message.label_file_search_service_news") }}</label>
                      <label v-else class="label-sm" for="service_news">{{
                        $t("message.label_file_search_program_title") }}</label>
                      <b-form-input type="text" v-model="searchServiceNews" id="service_news" size="sm"
                        autocomplete="off">
                        {{ searchServiceNews }}</b-form-input>
                    </b-form-group>
                  </div>
                  <!-- リリース時期 -->
                  <div class="w-30 ml-2">
                    <b-form-group class="form-group">
                      <label class="label-sm">{{ $t("message.label_file_search_release_date")
                        }}</label>
                      <DateRangePicker :localeData="{ firstDay: 1, format: 'yyyy/mm/dd', daysOfWeek: getDaysOnLocale }"
                        :autoApply=true :linkedCalendars=true :ranges=false :dateRange="searchReleaseDateRange"
                        v-model="searchReleaseDateRange" opens="right">
                      </DateRangePicker>
                    </b-form-group>
                  </div>
                </b-form>
              </div>
              <!-- 検索実行・クリアボタン -->
              <div class="col-12">
                <div class="d-flex justify-content-between mt-2 mb-2">
                  <div style="visibility:hidden"></div>
                  <div>
                    <svg-button normal variant="primary" size="sm" iconKey="search" title="common_button_search"
                      @onclick="executeFilter()">
                    </svg-button>
                  </div>
                  <div>
                    <a class="clear-atag-style" href="javascript:void(0)" v-on:click="clear()">
                      {{ $t("message.button_search_clear_title") }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </cstm-card>
        </div>
      </div>
    </div>
  </div>
  <div content-class="mt-3" :class="paneConfig.nextPaneBg">
    <div>
      <div v-if="!isReproPossible()" class="col-12 text-lg-center h6 pt-5">
        <strong v-html="$t(paneConfig.nextMessage)"></strong>
      </div>
      <div v-if="isReproPossible()" class="mt-2">
        <label>{{ $t('message.label_vehicle_system_translations') }}</label>
        <b-form-select v-model="selectedLanguage" id="translation_language" size="sm" @change="updateLanguage"
          class="w-25 ml-1 custom-select custom-select-sm">
          <option v-for="lng in translationLangs" :value="lng.value">{{ lng.text }}</option>
        </b-form-select>
      </div>
      <cstm-table v-if="isReproPossible()" :items="targetUnitDetails" hasTabs :columns="systemTableColumns"
        @rowClicked="clickRow" class="mt-2" type="simple" currentSortkey="release_date" initSortDir="desc" usePopup rowSelect>
        <template v-slot:contentRightEnd>
          <cstm-pagination class="mb-1" ref="paginationPFC" v-bind:pageSize='pageSize' v-bind:initPage=1
            v-bind:totalPageLength='totalItemLength' @selecedPage="selectedPage">
          </cstm-pagination>
        </template>
      </cstm-table>
    </div>
    <div class="col-12 text-center mt-5 mb-3">
      <b-button v-if="!processing" variant="outline-primary" style="background-color: white;" v-on:click="back()" class="mr-3 mb-5">
        {{ $t('message.common_button_back') }}</b-button>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>