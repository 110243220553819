import api from '../../common/api';
import util from '../../share/util/utils';
import handler from '../../share/util/apiResultValidator';
import config from '../../share/config';
import resource from '../../share/util/resourceMap';
import apiService from "../../share/util/api/service";
const publishedUnitListTestMode = require('../../data/testMode/publishedUnits.json');
const pfcFileListTestMode = require('../../data/testMode/pfcFileList.json');

export default {
  data() {
    return {

    };
  },
  namespaced: true,

  state: {
    // リプロ機能向けシステム一覧テーブル情報
    systemTable: null,
    // リプロ機能テーブルでのダイアログ表示制御用フラグ
    showSystemTblModal: true,
    // 診断情報一覧データ
    allDtcSystems: null,
    // ページネーション管理
    runPaginateData: false,
    // 商品コードテーブル
    merchandiseTable: null,
  },

  mutations: {
    setNativeSystemTable(state, payload) {
      state.systemTable = payload;
    },
    setSystemTblModalFlag(state, payload) {
      state.showSystemTblModal = payload;
    },
    setAllDtcTable(state, payload) {
      state.allDtcSystems = payload;
    },
    setRunPaginateData(state, payload) {
      state.runPaginateData = payload;
    },
    merchandiseCodeTbl(state, payload) {
      state.merchandiseTable = payload;
    }
  },

  actions: {
    // DTCテーブル一覧情報を取得
    async getDtcSystemTable(context, arg) {
      // Response結果をオブジェクトに入れる
      const getDtcResponse = {
        errorResult: null
      };
      if (arg.projectId && arg.measureId) {
        const endPoint = '/diagDtc/' + arg.projectId + '/' + arg.measureId;
        const res = await api.getCall(config.REPRO, endPoint);
        handler.validate(
          handler.validateTypes.all,
          res, null, null,
          () => {
            if (!res.data.clear_dtc_all_system_count && res.data.clear_dtc_all_system_count !== 0) {
              // clear_dtc_all_system_countが無かった場合
              res.data.clear_dtc_all_system_count = -1;
            }
            // 処理が成功したときデータを入れる
            context.commit('setAllDtcTable', res.data);
          },
          (result) => {
            // 処理が失敗してる場合
            getDtcResponse.errorResult = result;
          }, null, false);
        return getDtcResponse;
      }
      // measureIdが取れ無かった場合、エラーにはせず空配列で返す
      else {
        context.commit('setAllDtcTable', { clear_dtc_all_system_count: -1, data: [] });
        return getDtcResponse;
      }

    },
    // リプロ対象に応じた商品コードの詳細を取得する
    async merchandiseCodeTbl(context, arg) {
      // Published/UnPublishedのレスポンスから、商品コードを重複無しの状態で取り出す
      const unitListToMerchandiseCodes = function (unitList) {
        const merchandiseCodeSet = new Set();
        for (const unitInfo of unitList) {
          // テストモードのときは商品コードのリストが存在しないので空配列に置き換える
          const merchandiseCodeList = unitInfo.merchandise_code_list ?? [];
          for (const merchandiseCode of merchandiseCodeList) {
            merchandiseCodeSet.add(merchandiseCode);
          }
        }
        return merchandiseCodeSet;
      };
      const publishedMerchandiseCodes = unitListToMerchandiseCodes(arg.published);
      const unPublishedMerchandiseCodes = unitListToMerchandiseCodes(arg.unpublished);

      // Published/UnPublishedの商品コードを重複無しの状態にする
      const uniqueMerchandiseCodes = new Set();
      for (const merchandiseCodes of [publishedMerchandiseCodes, unPublishedMerchandiseCodes]) {
        for (const merchandiseCode of merchandiseCodes) {
          uniqueMerchandiseCodes.add(merchandiseCode);
        }
      }

      const merchandiseCodeDetails = {};
      for (const merchandiseCode of uniqueMerchandiseCodes) {
        // 各商品コードの詳細情報を取得する
        const merchandiseCodeDetail = await apiService.getCall(apiService.REPRO, '/merchandise/' + merchandiseCode);
        merchandiseCodeDetails[merchandiseCode] = merchandiseCodeDetail;
      }

      // 公開/非公開それぞれでアップデートプログラム一覧に表示する内容を設定する
      const publishedMerchandiseList = Array.from(publishedMerchandiseCodes).map(merchandiseCode => merchandiseCodeDetails[merchandiseCode]);
      const unPublishedMerchandiseList = Array.from(unPublishedMerchandiseCodes).map(merchandiseCode => merchandiseCodeDetails[merchandiseCode]);
      const merchandiseDetails = {
        pub: publishedMerchandiseList,
        unpub: unPublishedMerchandiseList,
      };
      context.commit('merchandiseCodeTbl', merchandiseDetails);
      return { isValid: true, data: null };
    },

    // テーブルを取得
    async setNativeSystemTable(context, arg) {
      if (arg.mode === 'testMode') {
        // Random select programmable status
        const publishedUnitListTestModeTemp = JSON.parse(JSON.stringify(publishedUnitListTestMode));
        for (let index = 0; index < publishedUnitListTestModeTemp.length; ++index) {
          if (Math.random() < 0.5) {
            publishedUnitListTestModeTemp[index].reprogrammable_info.normal.reprogrammable_status = config.SYSTEM_STATUS_NOT_REPROGRAMMABLE;
            publishedUnitListTestModeTemp[index].pfc_file_id = null;
          } else {
            // テストモード用に、pfcファイルリストに照合用のリプロ有のidデータを作成する
            pfcFileListTestMode.pfcFileIdList.push({
              "id": publishedUnitListTestModeTemp[index].id,
              "pfc_file_id": "1234-5678-90",
              "type": "normal"
            });
          }
          // Special handling for RCR to display target unit information
          if (publishedUnitListTestModeTemp[index].system_names.length > 1 &&
            publishedUnitListTestModeTemp[index].system_names[0].system_name === 'RCR_L') {
            const targetRomId = publishedUnitListTestModeTemp[index].target_romid;
            publishedUnitListTestModeTemp[index].target_romid = `LH：${targetRomId.slice(0, -1)}0  RH：${targetRomId.slice(0, -1)}2`;
          }
        }
        const publishResult = resource.getVehicleSystemStatus(publishedUnitListTestModeTemp, this.suFlow);
        for (let index = 0; index < publishResult.vehicleSystemList.length; ++index) {
          publishResult.vehicleSystemList[index].name = publishedUnitListTestModeTemp[index].name;
          publishResult.vehicleSystemList[index].fieldSpec.isCommunicatable = config.LIST_ITEM_DISABLE;
          publishResult.vehicleSystemList[index].fieldSpec.isInstallation = config.LIST_ITEM_DISABLE;
        }
        // テーブルをキャッシュ
        const tbl = {
          published: util.sortItems(publishedUnitListTestModeTemp, 'id', config.ASC),
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else if (arg.mode === 'techMode') {
        // テーブルをキャッシュ
        const tbl = {
          published: [],
          unpublished: []
        };
        context.commit('setNativeSystemTable', tbl);
        return { isValid: true, data: null };
      }
      else {
        if (!arg.netAppId) {
          return { isValid: false, data: null };
        }
        const pblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/published';
        const unpblEndpoint = '/netApps/' + arg.netAppId + '/vehicle/units/unpublished';
        let requestType = 'ssm5';
        if (arg.isSoldVehicle) {
          requestType = 'sold_vehicle';
        }

        let pblTbl = [];
        let unpblTbl = [];
        let errObject = null;

        // 公開テーブル取得
        const pblRes = await api.getCall(config.REPRO, pblEndpoint + '/?request_type=' + requestType);
        const isValid = handler.validate(
          handler.validateTypes.all, pblRes, arg.obj, null, null, (result) => {
            errObject = result;
          }, null, false
        );
        if (!isValid || errObject) return { isValid: isValid, data: errObject };
        pblTbl = pblRes.data;

        // 非公開テーブル取得
        if (arg.isBothTable) {
          const unpblRes = await api.getCall(config.REPRO, unpblEndpoint);
          const isValid = handler.validate(
            handler.validateTypes.all, unpblRes, arg.obj, null, null, (result) => {
              errObject = result;
            }, null, false
          );
          if (!isValid || errObject) return { isValid: isValid, data: errObject };
          unpblTbl = unpblRes.data;
        }
        let tbl = {};
        // 既販車の場合
        if (arg.isSoldVehicle) {
          tbl = {
            published: util.sortItems(pblTbl.pack_file_list, 'create_at', config.ASC),
            unpublished: [],
            countryGroups: pblTbl.country_groups,
            vehicle: pblTbl?.vehicle ?? null,
            modelYear: pblTbl?.model_year ?? null,
            model: pblTbl?.model ?? null,
            annualImprovements: pblTbl?.annual_improvements ?? null,
            lastEvaluatedKey: pblTbl?.last_evaluated_key
          };
        } else {
          tbl = {
            published: util.sortItems(pblTbl, 'id', config.ASC),
            unpublished: util.sortItems(unpblTbl, 'id', config.ASC)
          };
        }
        context.dispatch('merchandiseCodeTbl',tbl);
        context.commit('setNativeSystemTable', tbl);
        context.dispatch('getPaginateData', { endpoint: pblEndpoint, requestType: requestType, data: pblTbl });
        return { isValid: true, data: null };
      }

    },

    async getPaginateData(context, arg) {
      let errObject = null;
      if (util.hasLastEvaluatedKey(arg.data)) {
        context.commit('setRunPaginateData', true);
      }
      const paginateData = context.state.systemTable.published;

      // ページ分割パラメータ
      // frontend からlimitは設定しない。
      const params = {
        request_type: arg.requestType,
        last_evaluated_key: JSON.stringify(arg.data.last_evaluated_key)
      };

      while (context.state.runPaginateData) {

        if (!context.state.runPaginateData) return;
        const response = await api.getParamCall(config.REPRO, arg.endpoint, params);
        if (!context.state.runPaginateData) return;

        // APIレスポンスの検証
        handler.validate(
          handler.validateTypes.all, response, this, null,
          () => {
            // 処理が成功した場合
            // paginateData にレスポンス情報を追加する。
            response.data.pack_file_list.map((data) => {
              paginateData.push(data);
            });
          },
          // レスポンス不正時のコールバック
          (result) => {
            errObject = result;
          }, null, false
        );

        if (errObject) return;
        // 次のデータが存在するかチェックする
        if (util.hasLastEvaluatedKey(response.data)) {
          params["last_evaluated_key"] = JSON.stringify(response.data.last_evaluated_key);
          context.commit('setNativeSystemTable', { ...context.state.systemTable, published: paginateData, lastEvaluatedKey: JSON.stringify(response.data.last_evaluated_key) });
        }
        else {
          // 次のデータが存在しない場合も処理終了
          context.commit('setNativeSystemTable', { ...context.state.systemTable, published: paginateData, lastEvaluatedKey: JSON.stringify(response.data.last_evaluated_key) });
          context.commit('setRunPaginateData', false);
          return;
        }
      }
    },

    setSystemTblModalFlag(context, arg) {
      context.commit('setSystemTblModalFlag', arg);
    },

    // 情報を初期化
    clearTableData(context) {
      context.commit('setNativeSystemTable', null);
      context.commit('merchandiseCodeTbl', null);
      context.commit('setSystemTblModalFlag', true);
      context.commit('setAllDtcTable', null);
    },
  }
};